import { useLocation } from 'react-router-dom';
import { SUPPORT_EMAIL, UK_BF_CARRIER_SUP_HUB, INT_BF_CSH_PHONE } from '../constants/AppConstants';
import { NO_HELP_DETAILS, HELP_NAV_URL } from '../constants/AppUrlConstants';

const HelpDetails = () => {
  const { pathname } = useLocation();

  if (NO_HELP_DETAILS.includes(pathname)) return undefined;

  return (
    <details className="govuk-details" data-module="govuk-details">
      <summary className="govuk-details__summary">
        <span className="govuk-details__summary-text">Need help?</span>
      </summary>
      <div className="govuk-details__text">
        <h2 className="govuk-heading-s">Reporting a problem</h2>
        <p>
          If you&apos;re having problems using this service, email{' '}
          <a className="govuk-link" href={`mailto:${SUPPORT_EMAIL}`}>
            {SUPPORT_EMAIL}
          </a>
        </p>
        <h2 className="govuk-heading-s">Help with permission to travel checks</h2>
        <p>
          For non-technical UPT related queries please contact the UK Border Force Carrier Support
          Hub on {UK_BF_CARRIER_SUP_HUB} or {INT_BF_CSH_PHONE} from outside the UK.
        </p>

        <h2 className="govuk-heading-s">Getting clearance to enter the UK</h2>
        <p>
          To get clearance, contact the Border Force team in the region you&apos;ll be arriving at
          or departing from.
        </p>

        <p>
          You can find the Border Force contact numbers and more support on our{' '}
          <a className="govuk-link" href={HELP_NAV_URL}>
            help page
          </a>
          .
        </p>
      </div>
    </details>
  );
};

export default HelpDetails;
